import React, { FC, useEffect, useState } from 'react';

import { Chevron } from '~assets';
import { Grid, HugeButton, Spinner, Tag, Text } from '~atoms';
import { DEFAULT_GRID_PROPS, MOBILE_FOOTER_HEIGHT, MOBILE_HEADER_HEIGHT } from '~constants';
import { Meta } from '~meta';
import { TagItem } from '~postCards';
import { api } from '~services';
import { TagPostsProps } from '~types';
import { isDefined, log } from '~utils';

export const TagPosts: FC<TagPostsProps> = ({ cursor: serverCursor, posts, tag }) => {
  // Pagination state
  const [data, setData] = useState(posts);
  const [cursor, setCursor] = useState(serverCursor);
  const [hasMore, setHasMore] = useState(isDefined(cursor));
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleLoadMore = async () => {
    setIsLoadingMore(true);

    // ToDo Add SWR
    const response = await api.getTagPosts({
      cursor,
      idOrAlias: tag.alias,
    });

    if (!response.data) {
      setError('Кажется, что-то пошло не так! Попробуйте еще раз!');
      setIsLoadingMore(false);
      return;
    }

    const morePosts = response.data.posts ?? [];
    log('info', 'morePosts')({ morePosts });

    setData((prev) => [...prev, ...morePosts]);
    setHasMore(isDefined(response.data.cursor));
    setCursor(response.data.cursor);
    setError(null);
    setIsLoadingMore(false);
  };

  // При переходе по ссылкам в режиме SPA обновляются пропсы
  useEffect(() => {
    setData(posts);
    setHasMore(isDefined(serverCursor));
    setError(null);
  }, [serverCursor, posts]);

  return (
    <Grid>
      <Meta title={tag?.seoTitle} description={tag?.seoDescription} />
      <Grid
        {...DEFAULT_GRID_PROPS}
        $background="white"
        $color="black"
        $gap="30px"
        $padding="0"
        $xl={{ $padding: '15px 15px 20px' }}
        $sm={{
          $display: 'flex',
          $flexDirection: 'column',
          $justifyContent: 'flex-start',
          $minHeight: `calc(100vh - ${MOBILE_HEADER_HEIGHT} - ${MOBILE_FOOTER_HEIGHT})`,
        }}
      >
        <Grid
          $display="flex"
          $width="100%"
          $alignItems="center"
          $sm={{
            $alignItems: 'flex-start',
            $display: 'flex',
            $flexDirection: 'column',
          }}
        >
          <Tag
            $transparent
            $minWidth="122px"
            $position="relative"
            $top="unset"
            $right="unset"
            $weight={600}
            $fontSize="40px"
            $lineHeight="44px"
            $padding="5px 30px"
            $cursor="default"
            $lg={{ $fontSize: '30px' }}
            $sm={{ $fontSize: '24px', $lineHeight: '26px', $padding: '6px 16px' }}
          >
            {tag.name}
          </Tag>
        </Grid>
        <Grid $gap="20px">
          {data.map((item, index) => (
            <TagItem key={index} post={item} />
          ))}
        </Grid>
      </Grid>
      {hasMore && (
        <HugeButton
          $lg={{
            $borderWidth: '3px',
            $fontSize: '24px',
            $height: '80px',
            $lineHeight: '26px',
          }}
          $sm={{
            $borderWidth: '2px',
            $fontSize: '18px',
            $height: '60px',
            $lineHeight: '20px',
            $margin: '0 auto',
          }}
          onClick={handleLoadMore}
        >
          {isLoadingMore ? (
            <Spinner />
          ) : (
            <Grid $justifyItems="center" $gap="16px">
              <Grid $gridAutoFlow="column" $columnGap="16px" $alignItems="center">
                <Text
                  $size={32}
                  $weight={600}
                  $lg={{ $lineHeight: 26, $size: 24 }}
                  $sm={{ $lineHeight: 20, $size: 18 }}
                >
                  больше текстов
                </Text>
                <Grid $width="32px" $height="32px" $sm={{ $height: '20px', $width: '20px' }}>
                  <Chevron />
                </Grid>
              </Grid>
              {isDefined(error) && (
                <Grid>
                  <Text $size={16}>{error}</Text>
                </Grid>
              )}
            </Grid>
          )}
        </HugeButton>
      )}
    </Grid>
  );
};
