import { GridLayout } from 'pn-backend';

import { DEFAULT_DESCRIPTION, DEFAULT_TITLE, SectionAlias } from '~constants';
import {
  FullPost,
  MainBlackScience,
  MainCompilation,
  MainNews,
  MainOnSport,
  MainPostOfTheWeek,
  MainTop,
  News,
  OneBig,
  OneSpecProject,
  SomeSpecProjects,
  SomeTests,
  ThreeSmall,
  TwoStandard,
} from '~sectionLayouts';
import { SectionLayoutComponentMap, SectionTextMap } from '~types';

export const sectionLayoutMap: SectionLayoutComponentMap = {
  [GridLayout.FullPost]: FullPost,
  [GridLayout.MainBlackScience]: MainBlackScience,
  [GridLayout.MainCompilation]: MainCompilation,
  [GridLayout.MainNews]: MainNews,
  [GridLayout.MainOnSport]: MainOnSport,
  [GridLayout.MainPostOfTheWeek]: MainPostOfTheWeek,
  [GridLayout.MainTop]: MainTop,
  [GridLayout.News]: News,
  [GridLayout.OneBig]: OneBig,
  [GridLayout.OneSpecProject]: OneSpecProject,
  [GridLayout.SomeSpecProjects]: SomeSpecProjects,
  [GridLayout.SomeTests]: SomeTests,
  [GridLayout.ThreeSmall]: ThreeSmall,
  [GridLayout.TwoStandard]: TwoStandard,
  [GridLayout.MainNewsFull]: MainNews,
};

export const sectionTextMap: SectionTextMap = {
  [SectionAlias.Main]: 'больше текстов',
  [SectionAlias.News]: 'больше новостей',
  [SectionAlias.Articles]: 'больше статей',
  [SectionAlias.Cards]: 'больше карточек',
  [SectionAlias.Tests]: 'больше тестов',
  [SectionAlias.Corona]: 'больше по теме',
  [SectionAlias.SpecOperaciya]: 'больше по теме',
  [SectionAlias.BlackScience]: 'больше науки',
  [SectionAlias.OnSport]: 'больше спорта',
  [SectionAlias.Specs]: 'больше спецпроектов',
  [SectionAlias.About]: '',
  [SectionAlias.Advertisers]: '',
};

export const sectionTitleMap: SectionTextMap = {
  [SectionAlias.Main]: DEFAULT_TITLE,
  [SectionAlias.News]: 'Новости — Постньюс',
  [SectionAlias.Articles]: 'Статьи — Постньюс',
  [SectionAlias.Cards]: 'Карточки — Постньюс',
  [SectionAlias.Tests]: 'Тесты — Постньюс',
  [SectionAlias.Corona]: DEFAULT_TITLE,
  [SectionAlias.BlackScience]: 'Black Science — новости науки и технологий простым языком',
  [SectionAlias.OnSport]: 'На Спорте — самые важные новости российского и мирового спорта',
  [SectionAlias.Specs]: 'Спецпроекты — Постньюс',
  [SectionAlias.About]: 'О проекте — Постньюс',
  [SectionAlias.SpecOperaciya]: 'Спецоперация — Постньюс',
  [SectionAlias.Advertisers]: DEFAULT_TITLE,
};

export const sectionDescriptionMap: SectionTextMap = {
  [SectionAlias.Main]: DEFAULT_DESCRIPTION,
  [SectionAlias.News]: 'Рассказываем о главных событиях в России и мире простым языком.',
  [SectionAlias.Articles]:
    'Интервью, обзоры, эксплейнеры и аналитические заметки на сайте Постньюс.',
  [SectionAlias.Cards]: 'Разборы, инструкции и гайды на сайте Постньюс.',
  [SectionAlias.Tests]:
    'Интересные и залипательные тесты по фильмам, музыке, истории и другим темам. Проверьте свои знания — пройдите тест на сайте Постньюс!',
  [SectionAlias.Corona]: DEFAULT_DESCRIPTION,
  [SectionAlias.BlackScience]:
    'Научные открытия, свежие исследования и интересные факты о мире вокруг нас.',
  [SectionAlias.OnSport]:
    'Свежие новости, обзоры соревнований, интервью со спортсменами и аналитические статьи о спорте.',
  [SectionAlias.Specs]: 'Редакционные и партнерские спецпроекты Постньюс.',
  [SectionAlias.About]:
    'Постньюс — новое медиа, работающее в жанре explanatory journalism. Здесь публикуются новости, лонгриды и видео, которые объясняют.',
  [SectionAlias.SpecOperaciya]:
    'Все самое важное о событиях специальной военной операции на Украине.',
  [SectionAlias.Advertisers]: DEFAULT_DESCRIPTION,
};
