import { GridPagination } from 'pn-backend';
import { inc, isEmpty, pipe, prop, propEq } from 'ramda';
import React, { FC, useEffect, useState } from 'react';

import { Chevron } from '~assets';
import { Grid, HugeButton, Spinner, Text } from '~atoms';
import { ErrorFallback } from '~components';
import { DEFAULT_DESCRIPTION, DEFAULT_TITLE, SectionAlias } from '~constants';
import { useLayout } from '~hooks';
import { Meta } from '~meta';
import {
  sectionDescriptionMap,
  sectionLayoutMap,
  sectionTextMap,
  sectionTitleMap,
} from '~sectionMaps';
import { api } from '~services';
import { SectionProps } from '~types';
import { getSectionText, isDefined, log, renderGridLayoutSchema } from '~utils';

export const Section: FC<SectionProps> = ({
  hasNext,
  pagination,
  results: gridSchemas = [],
  sectionAlias,
}) => {
  log('info', 'renderSection')({ gridSchemas, hasNext, sectionAlias });

  const { isAbout, isAdvertisers, isBlackScience, isMain, isSport } = useLayout();
  const isCustomPage = !isMain;
  const hasNoPosts = gridSchemas.every(pipe(prop('posts'), isEmpty));

  // Pagination state
  const [data, setData] = useState(gridSchemas);
  const [hasMore, setHasMore] = useState(hasNext);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(0);
  const [error, setError] = useState<string>();

  const handleLoadMore = async () => {
    setIsLoadingMore(true);

    // ToDo Add SWR
    const response = await api.getSectionGrid({
      idOrAlias: sectionAlias,
      page: inc(page),
    });

    if (!response.data) {
      setError('Кажется, что-то пошло не так! Попробуйте еще раз!');
      setIsLoadingMore(false);
      return;
    }

    const moreLayouts = response.data.results ?? [];
    log('info', 'moreLayouts')({ moreLayouts });

    setData((prev) => {
      switch (pagination) {
        case GridPagination.Inner:
          return prev.map((prevGridSchema) => ({
            ...prevGridSchema,
            // Перебираем все схемы и дополняем их новыми постами
            posts: prevGridSchema.posts.concat(
              moreLayouts.find(propEq('id', prevGridSchema.id))?.posts ?? [],
            ),
          }));
        case GridPagination.Outer:
        default:
          return [...prev, ...moreLayouts];
      }
    });
    setHasMore(response.data.hasNext);
    setPage(inc);
    setError(undefined);
    setIsLoadingMore(false);
  };

  // При переходе по ссылкам в режиме SPA обновляются пропсы
  useEffect(() => {
    setData(gridSchemas);
    setHasMore(hasNext);
    setPage(0);
    setError(undefined);
  }, [gridSchemas, hasNext, sectionAlias]);

  if (hasNoPosts) {
    return <ErrorFallback description="здесь пока ничего нет, но скоро обязательно будет!" />;
  }

  const metaTitle = getSectionText(sectionTitleMap, DEFAULT_TITLE)(sectionAlias as SectionAlias);

  const metaDescription = getSectionText(
    sectionDescriptionMap,
    DEFAULT_DESCRIPTION,
  )(sectionAlias as SectionAlias);

  const loadMoreText = getSectionText(
    sectionTextMap,
    'больше по теме',
  )(sectionAlias as SectionAlias);

  return (
    <Grid>
      <Meta title={metaTitle} description={metaDescription} />

      <Grid
        $margin={isAbout || isAdvertisers ? '0 0 20px' : '0 0 60px'}
        $gap={isCustomPage ? '40px' : '20px'}
        $xl={{ $gap: isCustomPage ? '30px' : '20px', $margin: '0 0 50px' }}
        $sm={{ $gap: '30px', $margin: isAbout || isAdvertisers ? '0 0 20px' : '0 0 40px' }}
      >
        {data.map(renderGridLayoutSchema(sectionLayoutMap))}
      </Grid>
      {hasMore && (
        <HugeButton
          $width="100vw"
          $left="50%"
          $right="50%"
          $margin="10px -50vw 0"
          $blackScience={isBlackScience}
          $sport={isSport}
          $lg={{
            $borderWidth: '3px',
            $fontSize: '24px',
            $height: '80px',
            $lineHeight: '26px',
          }}
          $sm={{
            $borderWidth: '2px',
            $fontSize: '18px',
            $height: '60px',
            $left: 'unset',
            $lineHeight: '20px',
            $margin: '0 auto',
            $right: 'unset',
          }}
          onClick={handleLoadMore}
        >
          {isLoadingMore ? (
            <Spinner />
          ) : (
            <Grid $justifyItems="center" $gap="16px">
              <Grid $gridAutoFlow="column" $columnGap="10px" $alignItems="center">
                <Text
                  $size={32}
                  $weight={600}
                  $lg={{ $lineHeight: 26, $size: 24 }}
                  $sm={{ $lineHeight: 20, $size: 18 }}
                >
                  {loadMoreText}
                </Text>
                <Grid $width="32px" $height="32px" $sm={{ $height: '20px', $width: '20px' }}>
                  <Chevron />
                </Grid>
              </Grid>
              {isDefined(error) && (
                <Grid>
                  <Text $size={16}>{error}</Text>
                </Grid>
              )}
            </Grid>
          )}
        </HugeButton>
      )}
    </Grid>
  );
};
